
import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueWithCompiler from 'vue/dist/vue.esm.js';
import { Component, Prop, Watch } from 'vue-property-decorator';
import NestedForm from '@/components/form/NestedForm.vue';
@Component({
  components: {
    NestedForm,
  },
})
export default class RenderDynamic extends Vue {
  @Prop({ type: String, required: true, default: '' }) html!: string;

  templateRender = () => {};

  render() {
    return this.templateRender();
  }

  created() {
    this.updateRender();
  }

  updateRender() {
    const compiled = VueWithCompiler.compile(`<div>${this.html}</div>`);
    this.templateRender = compiled.render;
    this.$options.staticRenderFns = [];
    for (const staticRenderFunction of compiled.staticRenderFns) {
      this.$options.staticRenderFns.push(staticRenderFunction);
    }
  }

  @Watch('html')
  onHtmlChange() {
    this.updateRender();
  }
}
