import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { TOLockEnvironment, TOLockTarget } from '@/types/two-otters.types';
import type { DeckConfig } from '@/components/form/dynamic-form.types';

export function getRegistrationService(
  serviceName: string,
  catalog: TOLockTarget,
  env: TOLockEnvironment,
  format = 'json',
): AxiosPromise<any> {
  return axios.get(URLs.AdminRegistrationServiceGetSingle, {
    params: {
      name: serviceName,
      catalog,
      env,
      format,
    },
  });
}

export function saveRegistrationService(serviceTag: string, deck: string): AxiosPromise<string> {
  return axios.post(
    URLs.AdminRegistrationServiceSetSingle,
    { deck },
    {
      params: {
        tag: serviceTag,
      },
    },
  );
}

export function validateRegistrationService(
  serviceName: string,
  catalog: TOLockTarget,
  env: TOLockEnvironment,
  tags: string[],
  deck: DeckConfig,
): AxiosPromise<string> {
  return axios.post(URLs.AdminRegistrationServiceValidateSingle, {
    catalog,
    env,
    name: serviceName,
    tags,
    file: deck,
  });
}

export function saveFormConfiguration(config: string): AxiosPromise<string> {
  return axios.post(URLs.AdminRegistrationSetFormConfiguration, { config });
}

export function convertFormConfiguration(config: string): AxiosPromise<string> {
  return axios.post(URLs.AdminRegistrationConvertFormConfiguration, { config });
}

export function getFormConfiguration(): AxiosPromise<string> {
  return axios.get(URLs.AdminRegistrationGetFormConfiguration);
}
export function getDefaultFormConfiguration(): AxiosPromise<string> {
  return axios.get(URLs.AdminRegistrationGetDefaultFormConfiguration);
}

export function loadRegistrationFormRenderer(): AxiosPromise<string> {
  return axios.get(URLs.AdminRegistrationGetRegistrationFormRenderer);
}
