
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { find, debounce, cloneDeep, isEmpty } from 'lodash';
import * as types from './form.types';
import { DynamicRegFormModule } from '@/store/modules/dynamic-registration.module';
import { DeckConfig, DynamicComponent, DynamicFormField, FormFieldValidaiton } from './dynamic-form.types';
import { runDynamicCustomValidators, fieldIndex, getSectionById } from '@/components/form/utils';
import { randId } from '@/utils/components';
import { FieldKeys } from '@/types/registration.types';
import { getDeckPropertyValue, setDeckPropertyValue } from '@/utils/dynamic-registration';
import { AppEnum, ensureAppEnum, Platform } from '@/types/enum.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { AUTH_SRC, RouteNames } from '@/constants';

type VObs = InstanceType<typeof ValidationObserver>;
@Component({
  components: {
    'g-select': () => import('@/components/gsk-components/GskSelect.vue'),
    'g-multi-select': () => import('@/components/gsk-components/GskMultiSelect.vue'),
    'g-radio': () => import('@/components/gsk-components/GskRadioGroup.vue'),
    'g-checkbox-group': () => import('@/components/gsk-components/GskCheckboxGroup.vue'),
    'g-checkbox': () => import('@/components/gsk-components/GskCheckbox.vue'),
    'g-switch': () => import('@/components/gsk-components/GskSwitch.vue'),
    'g-input-chips': () => import('@/components/gsk-components/GskInputChips.vue'),
    'g-text-field': () => import('@/components/gsk-components/GskTextfield.vue'),
    'g-people-picker': () => import('@/components/gsk-components/GskPeoplePicker.vue'),
    'g-people-picker-table': () => import('@/components/PeoplePickerTable.vue'),
    'repo-picker': () => import('@/components/RepoPicker.vue'),
    'g-image-upload': () => import('@/components/gsk-components/GskImageUpload.vue'),
    'key-value-input': () => import('@/components/form/KeyValueInput/KeyValueInput.vue'),
    'open-api-spec': () => import('@/components/OpenApiDocumentEditor.vue'),
    'rpa-category-input': () => import('@/features/om27/components/CategoryInput.vue'),
    'g-autocomplete': () => import('@/components/gsk-components/GskAutocomplete.vue'),
    ValidationProvider,
    ValidationObserver,
  },
})
export default class DynamicFormItem extends Vue {
  @Prop({ type: Object, required: true }) field!: DynamicFormField;
  @Prop(Boolean) disabled!: boolean;
  @Prop({ type: String, default: Platform.AZURE }) platformName!: string;
  $refs!: {
    topObserver: VObs;
  };
  serverErrors: Partial<Record<FieldKeys, string>> = {};

  public componentMap: DynamicComponent[] = [
    {
      type: 'textfield',
      name: 'g-text-field',
      props: {
        'validated': true,
      }
    },
    {
      type: 'number',
      name: 'g-text-field',
      props: {
        'validated': true,
      }
    },
    {
      type: 'textarea',
      name: 'g-text-field',
      props: {
        'validated': true,
      }
    },
    {
      type: 'checkbox',
      name: 'g-checkbox-group',
      props: {
        inline: true
      }
    },
    {
      type: 'select',
      name: 'g-select',
      props: {
        enhanced: true
      }
    },
    {
      type: 'multi-select',
      name: 'g-multi-select'
    },
    {
      type: 'radio',
      name: 'g-radio'
    },
    {
      type: 'checkbox-single',
      name: 'g-checkbox',
      props: {
        inline: true
      }
    },
    {
      type: 'switch',
      name: 'g-switch'
    },
    {
      type: 'input-chips',
      name: 'g-input-chips'
    },
    {
      type: 'people-picker',
      name: 'g-people-picker'
    },
    {
      type: 'peoplepickertable',
      name: 'g-people-picker-table'
    },
    {
      type: 'github-repo',
      name: 'repo-picker'
    },
    {
      type: 'image-upload',
      name: 'g-image-upload',
      props: {
        accept: "image/*",
        condensed: true,
      }
    },
    {
      type: 'rpacategoryinput',
      name: 'rpa-category-input'
    },
    {
      type: 'autocomplete',
      name: 'g-autocomplete'
    },
    {
      type: 'key-value',
      name: 'key-value-input'
    },
    {
      type: 'open-api',
      name: 'open-api-spec'
    }
  ]

  get currentComponent() {
    return find(this.componentMap, item => item.type === this.field.type);
  }

  get env(): AppEnum {
    const penvId = this.$route.params.env;
    const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[penvId]?.environmentId ?? -1;
    return ensureAppEnum(EnumsModule.enumsById.environment[envId]);
  }

  get oAuthMnemonic() {
    return DynamicRegFormModule.oAuthMnemonicVal;
  }

  get optionsVal() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const options = this.field.options;
    const filterFunction = this.field.filterOptionsByOAuth;
    const oAuthType = this.oAuthMnemonic && AUTH_SRC[this.oAuthMnemonic].readableName;
    if(oAuthType && filterFunction && typeof(filterFunction) === "function") {
      return filterFunction(options, oAuthType);
    }
    return options;
  }

  checkComponentStatus(field: DynamicFormField) {
    const fields = DynamicRegFormModule.showOnlyFieldsWithNames;
    return fields.length > 0 ? fields.indexOf(field.name) !== -1 : true;
  }

  fieldStyle(field: types.FormField): { gridColumn?: string } {
    if (field.layout?.gridColumn) {
      return { gridColumn: field.layout?.gridColumn };
    }
    return {};
  }

  checkMaxLength(field: DynamicFormField): string | undefined {
    if ('maxLength' in field) {
      return field.maxLength;
    }
    return undefined;
  }

  getFieldPlaceholder(field: DynamicFormField): string | undefined {
    if ('placeholder' in field) {
      return field.placeholder;
    }
    return undefined;
  }

  getFieldTooltip(field: DynamicFormField): string | undefined {
    if ('tooltipText' in field) {
      return field.tooltipText;
    }
    return undefined;
  }

  async getFieldChoices(query: string): Promise<string | undefined> {
    const getChoicesFunc = this.field.getChoices;
    const choices = getChoicesFunc && await getChoicesFunc(query);
    const data = await choices.json()
    return data.map((res: { id: any; name: any; }) => ({ label: `${res.id} - ${res.name}`, value: res.id }));
  }

  getFieldValue(field: DynamicFormField): any {
  if (
      (field.type === 'textfield' || field.type === 'number' || field.type === 'long-text') &&
      field.value === undefined
    ) {
      return '';
    } else if (
      ['textfield', 'number', 'long-text', 'input-chips'].includes(field.type) &&
      field.dataType === 'array' &&
      Array.isArray(field.value)
    ) {
      return field.value.join(',');
    } else if(field.type === 'open-api') {
      if(!isEmpty(field.value)) {
        return field.value;
      } else {
        return typeof(DynamicRegFormModule.openApiSpecData) === 'object'? JSON.stringify(DynamicRegFormModule.openApiSpecData): '';
      }
    } 
    return field.value;
  }
  
  get isEdit(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewEdit;
  }

  get isVersion() {
    return this.$route.name === RouteNames.RegisterApiNewVersion;
  }

  get dataContext() {
    return DynamicRegFormModule?.deckConfig?.services?.[0] || {};
  }

  getRequiredStatus(field: DynamicFormField) {
    if (typeof field.required !== 'function') {
      return field.required;
    }
    const parentSectionId = field.parentSectionId;
    const section = getSectionById(DynamicRegFormModule.allFormSectionItems, parentSectionId);
    const fieldIdx = section?.items ? fieldIndex(section.items): new Map<string, DynamicFormField>();

    let mode = 'create';
    if (this.isEdit) mode = 'edit';
    if (this.isVersion) mode = 'version';
    return field.required(fieldIdx, mode, this.env.name, this.dataContext);
  }

  isValid(v: { errors: string[] }): boolean {
    return v.errors.length === 0;
  }

  asyncValidators: Record<string, string> = {};
  asyncValidate = debounce(this.customValidate, 1000);
  customValidate(field: DynamicFormField): void {
    if (field.customValidator) {
      const id = randId();
      // try to mitigate race condition if there's multiple reqs out at once
      this.$set(this.asyncValidators, field.key, id);
      runDynamicCustomValidators([field] as DynamicFormField[], DynamicRegFormModule.allFormSectionItems, this.env, DynamicRegFormModule.resourceData, 
      this.dataContext, true ,ProjectDetailsModule.proxyPathList,this.platformName).then(res => {
        if (res.hasErr && this.asyncValidators[field.key] === id) {
          this.$emit('custom-validate', res.serverErrors)
        }
      });
    }
  }

  validation(field: DynamicFormField): FormFieldValidaiton | Record<string, never> {
    if (field.validation === undefined) {
      return {
        vid: field.key,
      };
    }
    const isRadio = field.type === 'radio';
    const validation: FormFieldValidaiton = { ...field.validation, vid: field.key };

    if (isRadio) {
      validation.immediate = true;
      validation.mode = 'aggressive';
    }
    if (validation.name === undefined) {
      validation.name = field.label;
    }

    return validation;
  }

  helpText(field: DynamicFormField): string {
    return field.supportingText || '';
  }

  isDisabled(field: DynamicFormField): string | boolean {
    return field.attrs?.disabled || this.disabled;
  }

  get componentProps() {
    return { ...this.currentComponent.props };
  }

  get activeSectionKey() {
    return DynamicRegFormModule.selectedFormSectionKey;
  }

  dynamicFormHandler(field: DynamicFormField, value: types.FormField['value']) {
    const deckConfig: DeckConfig = JSON.parse(JSON.stringify(DynamicRegFormModule.deckConfig));
    setDeckPropertyValue(field, value, deckConfig, this.activeSectionKey, DynamicRegFormModule.formSections);
    DynamicRegFormModule.setDeckConfig(deckConfig);
  }

  mounted(): void {
    if (this.field.default) {
      const parentSectionId = this.field.parentSectionId;
      const section = getSectionById(DynamicRegFormModule.allFormSectionItems, parentSectionId);
      const fieldIdx = section?.items
        ? fieldIndex(section.items)
        : new Map<string, DynamicFormField>();

      const dataContext = DynamicRegFormModule?.deckConfig?.services?.[0] || {};
      const value = getDeckPropertyValue(this.field, dataContext, fieldIdx);
      this.onChange(this.field, value, false);
    }
  }

  onChange(field: DynamicFormField, value: DynamicFormField['value'], customValidate = true) {
    if (value === undefined) {
      return cloneDeep(this.field);
    }
    this.dynamicFormHandler(field, value);
    DynamicRegFormModule.updateRegFormDataById({ ...field, value: value } as DynamicFormField);
    if (customValidate) {
      DynamicRegFormModule.updateDataChangeStatus(true);
      this.customValidate(field);
    }
    if (field.runSectionValidatorOnChange) {
      this.$emit('runSectionValidator');
    }
  }
}
