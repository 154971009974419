
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import RenderDynamic from '@/components/form/RenderDynamic.vue';

@Component({
  components: {
    RenderDynamic,
  },
})
export default class RenderDynamicContainer extends Vue {
  @Prop({ type: String, required: true, default: '' }) html!: string;

  show = true;

  @Watch('html')
  onHtmlChange() {
    this.show = false;
    this.$nextTick().then(() => {
      this.show = true;
    });
  }
}
