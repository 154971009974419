import { validate } from 'vee-validate';
import { FormField, ServerErrors } from '@/components/form/form.types';
import { FormSections } from '@/store/modules/dynamic-registration.module';
import { DynamicFormField } from './dynamic-form.types';
import { keyBy } from 'lodash';
import { AppEnum } from '@/types/enum.types';

/**
 * Old validator from before we brought in vee-validate
 * @param form
 */
export function isValid(form: FormField[]) {
  return form.reduce((isValid: boolean, field) => {
    if (isValid) {
      if (field.required && !field.hide) {
        if (Array.isArray(field.value)) {
          return !!field.value.length;
        }

        return !!field.value;
      }
    }
    return isValid;
  }, true);
}

export function runCustomValidator(field: FormField) {
  return runCustomValidators([field]);
}

export function runCustomValidators(fields: FormField[]) {
  const errs: Promise<[string, string]>[] = [];
  for (const field of fields) {
    if (field.customValidator) {
      errs.push(field.customValidator(field.value as never).then(message => [field.key, message]));
    }
  }
  return Promise.all(errs).then(e => {
    const serverErrors: ServerErrors = {};
    let hasErr = false;
    for (const [key, message] of e) {
      if (message) {
        serverErrors[key] = message;
        hasErr = true;
      }
    }
    return { serverErrors, hasErr };
  });
}

export function flattenedItemsFor(tree: DynamicFormField[]): DynamicFormField[] {
  return tree.flatMap(item => item.items ? [item, ...flattenedItemsFor(item.items)] : item);
}

export function fieldIndex(items: DynamicFormField[]) {
  const allFields = flattenedItemsFor(items).filter((item) => {
    return item.itemType === 'field'
  });
  const fieldIdx = new Map<string, DynamicFormField>();
  allFields.forEach(ref => {
    fieldIdx.set(ref.name, ref)
  });
  return fieldIdx;
}

export function getSectionById(allSections: FormSections[], parentSectionId: string | undefined) {
  let arr: any[] = [];
  allSections.forEach((section) => {
    arr = arr.concat(section.value);
  });
  return arr.find((item) => item.sectionId === parentSectionId);
}

export function runDynamicCustomValidators(fields: DynamicFormField[], allSections: FormSections[], env: AppEnum, resource: any, 
  dataContext: any, isNotFormValidation?: boolean, proxyPathList? : any, platform?: string) {
  const errs: Promise<[string, string]>[] = [];
  for (const field of fields) {
    if (field.customValidator) {
      const parentSectionId = field.parentSectionId;
      const section = getSectionById(allSections, parentSectionId);
      const fieldIdx = section?.items? fieldIndex(section.items): new Map<string, DynamicFormField>();
      errs.push(field.customValidator(field, fieldIdx, env, resource, dataContext, isNotFormValidation, proxyPathList,platform).then(message => [field.key, message] as [string, string]));
    }
    if (field.type === 'key-value') {
      if (field.keyValidation || field.valueValidation) {
        for (const keyValuePair of field.value) {
          if (field.keyValidation) {
            errs.push(validate(keyValuePair.key, field.keyValidation?.rules).then(keyResult => [field.key, keyResult.valid ? '' : keyResult.errors[0]] as [string, string]))
          }
          if (field.valueValidation) {
            errs.push(validate(keyValuePair.value, field.valueValidation?.rules).then(keyResult => [field.key, keyResult.valid ? '' : keyResult.errors[0]] as [string, string]))
          }
        }
      }
    }
  }
  return Promise.all(errs).then(e => {
    const serverErrors: ServerErrors = {};
    let hasErr = false;
    for (const [key, message] of e) {
      if (message) {
        serverErrors[key] = message;
        hasErr = true;
      }
    }
    return { serverErrors, hasErr };
  });
}

export function removeHiddenFields(fields: FormField[]): FormField[] {
  const keyedFiels = keyBy(fields, 'key');
  return fields.filter(field => {
    if (field.hideIf) {
      if (typeof field.hideIf === 'function') {
        return !field.hideIf(fields, field);
      } else {
        if (keyedFiels[field.hideIf.fieldKey]?.value === field.hideIf.fieldValue) {
          return false;
        }
      }
    }

    return true;
  });
}
