
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ListItemBase } from '@gsk-tech/gsk-list/gsk-list-base';
import NavigationMixin from '@/components/mixins/navigation.mixin';
import { GroupedNavigationItem, UINavigationItem } from '@/types';
import { get } from 'lodash';

@Component
export default class ExpandableNavigationList extends mixins(NavigationMixin) {
  @Prop({ required: true, type: Array })
  public readonly groups!: GroupedNavigationItem[];
  @Prop(Boolean) bold!: boolean;
  @Prop(Boolean) primary!: boolean;

  @Prop({ required: false, type: Boolean }) deleteIcon!: boolean;
  @Prop({ required: false, type: Boolean }) addIcon!: boolean;
  @Prop({ required: false, type: String }) disableDeleteKey!: string;
  @Prop({ required: false, type: Boolean }) isDynamicForm!: boolean;

  // ignore this for now
  @Prop({ required: false, type: Array })
  public declare readonly links: never;

  get activeGroupIndex() {
    return this.groups.findIndex((group) => {
      if('children' in group) {
        const activeChild = group.children.find((childGroup) => {
          return childGroup.key === this.activeItem?.key;
        })
        return activeChild !== undefined;
      } else {
        return group.key === this.activeItem?.key;
      }
    });
  }

  get derivedLinks(): UINavigationItem[] {
    return this.groups.flatMap(g => ('children' in g ? g.children : [g]));
  }

  isValid(item: any): boolean {
    return !get(item, 'valid', false);
  }

  mergeClasses(link: UINavigationItem) {
    return { ...this.classes(link), bold: this.bold };
  }

  mounted() {
    this.$nextTick(async () => {
      const li: ListItemBase | null = document.querySelector('.enl-group.expand');
      if (li) {
        await li.updateComplete;
        li.expand();
      }
    });
  }

  addNewRoute(evt: any) {
    this.$emit('add-new-route', evt);
  }
  deleteRoute(evt: any) {
    this.$emit('delete-route', evt);
  }
}
