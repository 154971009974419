
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class Pane extends Vue {
  @Prop({type: String, default: '100%'}) initWidth!: string;
  @Prop({ type: String, default: '100%' }) initHeight!: string;
  @Prop({ type: String }) classes!: string;
  @Prop({ type: Boolean, default: true }) defaultScrollbar!: boolean;
  width() {
    return this.initWidth;
  }
  height() {
    return this.initHeight;
  }
}
