
import { Component, Vue, Prop } from 'vue-property-decorator';
import get from 'lodash/get';

@Component({})
export default class MultiSplitPane extends Vue {
  @Prop({type: String, required: true, default: '100%' }) height!: string;
  @Prop({ type: String, default: '100%' }) width!: string;
  @Prop({ type: String }) classes!: string;
  @Prop({ type: String, default: 'vertical' }) split!: string;
  @Prop({ type: String, default: '10px' }) resizerMinWidth!: string;
  @Prop({ type: Boolean, default: false }) nested!: boolean;

  public root:any = null;
  public panes:any[] = [];
  public resizers:any[] = [];
  public fracs:number[] = [];
  public pos: any[] = [];
  public collapsedPanes: number[] = [];
  public display:any = null;

    setFracs(fracArray: any[]) {
      this.fracs = fracArray
      this.initPos()
      this.applyFracs()
    }
    getDistance(orientation: string) {
      if (orientation == 'horizontal') {
        return this.root.getBoundingClientRect().left
      } else {
        return this.root.getBoundingClientRect().top
      }
    }
    getDimension(orientation: string) {
      if (orientation == 'horizontal') {
        return this.root.clientWidth
      } else {
        return this.root.clientHeight
      }
    }
    getResizerDimension(orientation: string) {
      if (orientation == 'horizontal') {
        return this.resizers[0].offsetWidth
      } else {
        return this.resizers[0].offsetHeight
      }
    }
    addDragLogic(i: number, resizer: any) {
      if (i === 0) return // The first pane size can not be changed!

      let eDownFunc = (eDown: any) => {
        eDown.preventDefault()

        if (eDown.type == 'touchstart') eDown = eDown.touches[0]

        let shift: number

        if(this.split == 'horizontal') {
          shift = eDown.pageX - resizer.getBoundingClientRect().left;
        } else {
          shift = eDown.pageY - resizer.getBoundingClientRect().top;
        }
      
        let onMouseMove = (eMove: any) => {
          if (eMove.type == 'touchmove') eMove = eMove.touches[0]
          let coor = this.split == 'horizontal' ? eMove.pageX : eMove.pageY

          let numerator =
            coor -
            shift -
            this.getDistance(this.split) -
            this.getResizerDimension(this.split) * i
          let nominator =
            this.getDimension(this.split) -
            this.getResizerDimension(this.split) * this.panes.length

          if(numerator + parseInt(this.resizerMinWidth) > nominator) {
            return;
          }

          let newPos = numerator / nominator

          if (newPos < 0) newPos = 0
          if (newPos > 1) newPos = 1

          this.updatePos(i, newPos)
          this.updateFracs();
          this.applyFracs()
        }

        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', onMouseMove);
            this.$emit('resizeEnd');
          }
        )

        window.addEventListener('touchmove', onMouseMove)
        window.addEventListener('touchend', () => {
            window.removeEventListener('touchmove', onMouseMove)
            this.$emit('resizeEnd');
          }
        )
      }
      resizer.addEventListener('mousedown', eDownFunc)
      resizer.addEventListener('touchstart', eDownFunc)
    }
    initPos() {
      this.pos = []
      let cumulative = 0

      this.panes.forEach(pane => {
        if (this.split == 'horizontal') {
          pane.querySelector('.v-resizer').style.minWidth = this.resizerMinWidth
          pane.classList.add('horizontal')
        } else {
          pane.querySelector('.v-resizer').style.minHeight = this.resizerMinWidth
          pane.classList.add('vertical')
        }
      })

      for (const frac of this.fracs) {
        this.pos.push(cumulative)
        cumulative += frac;
      }
    }
    updatePos(resizerI: number, newPos: number) {
      if (newPos < this.pos[resizerI]) {
        for (let i = resizerI - 1; i >= 0; i--) {
          let iPos = this.pos[i]

          if (iPos < newPos) break
          else this.pos[i] = newPos
        }
      } else {
        for (let i = resizerI + 1; i < this.pos.length; i++) {
          let iPos = this.pos[i]

          if (iPos > newPos) break
          else this.pos[i] = newPos
        }
      }

      this.pos[resizerI] = newPos
    }
    updateFracs() {
      this.fracs = []
      for (let i = 0, j = 1; i < this.pos.length; i++, j++) {
        let iPos = this.pos[i]
        let jPos = j === this.pos.length ? 1 : this.pos[j]
        this.fracs.push(jPos - iPos)
      }
    }
    applyFracs() {
      let getStyleStr = (frac: any) => {
        let dim = this.split == 'horizontal' ? 'width' : 'height'

        return `
              ${dim}: calc((100% - ${this.panes.length *
          this.getResizerDimension(
            this.split
          )}px) * ${frac} + ${this.getResizerDimension(this.split)}px);
            `
      }
      this.$emit('onPaneResize');
      this.panes.forEach((pane, i) => {
        let iFrac = this.fracs[i]

        if (iFrac === 0 && this.collapsedPanes[i] !== 1) {
          pane.classList.add('collapsed')
          this.collapsedPanes[i] = 1
          this.$emit('onPaneCollapsed', i, pane.classList, this.root.classList)
        } else if (iFrac !== 0 && this.collapsedPanes[i] === 1) {
          pane.classList.remove('collapsed')
          this.collapsedPanes[i] = 0
          this.$emit('onPaneExpanded', i, pane.classList, this.root.classList)
        }

        pane.setAttribute('style', getStyleStr(iFrac))
      })
    }
  mounted() {
    if(this.split === 'horizontal') {
      this.display = 'flex'
    } else {
      this.display = 'block'
    }
    
    this.collapsedPanes.fill(0, 0, get(this.$slots.default, 'length', 0));

    this.root = this.$refs.resizable

    if (this.nested) {
      this.panes = this.root.querySelectorAll(
        '[data-resizable].nested > .v-pane'
      )
      // Looking for resizers
      this.resizers = this.root.querySelectorAll(
        '[data-resizable].nested > .v-pane > [data-resizer]'
      )
    } else {
      this.panes = this.root.querySelectorAll('[data-resizable] > .v-pane')
      this.resizers = this.root.querySelectorAll(
        '[data-resizable] > .v-pane > [data-resizer]'
      )
    }

    // Calculating initial fracs and pos
    let initFrac = 1 / this.panes.length
    this.panes.forEach(() => this.fracs.push(initFrac))

    this.initPos()
    this.applyFracs()

    // Adding drag logic to resizers
    this.resizers.forEach((resizer, i) => this.addDragLogic(i, resizer))
  }
}
