
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { filter, get, debounce } from 'lodash';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';
import NestedForm from '@/components/form/NestedForm.vue';
import { GroupedNavigationItem } from '@/types';
import ApiRegistrationContainer from '@/views/api-registration/ApiRegistrationContainer.vue';
import { runDynamicCustomValidators, flattenedItemsFor } from '@/components/form/utils';
import { DynamicRegFormModule, FormSections } from '@/store/modules/dynamic-registration.module';
import { DeckConfig, DynamicConfig, DynamicFormField, ValidationStatus } from './form/dynamic-form.types';
import ExpandableNavigationList from '@/components/ExpandableNavigationList.vue';
import GskSwitch from '@/components/gsk-components/GskSwitch.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { AppEnum, ensureAppEnum, Platform } from '@/types/enum.types';
import { EnumsModule } from '@/store/modules/enums.module';
import GAnalytics from '@/components/GAnalytics';
import { RouteNames } from '@/constants';
import { ClickData } from "@/analytics";
import { ProjectsModule } from '@/store/modules/projects.module';
import * as API from '@/api/projects.api';
import _ from 'lodash';


@Component({
  components: {
    GskSwitch,
    ExpandableNavigationList,
    ApiRegistrationContainer,
    GTextfield,
    GButton,
    GridCell,
    Grid,
    NestedForm,
    MaxWidth,
    GDialog,
    GAnalytics,
  },
})
export default class RegistrationFormRenderer extends Vue {
  @Prop({ type: String, required: true }) rendererData!: string;
  @Prop({ type: Boolean }) hideCreate!: boolean;
  @Prop({ type: Boolean }) processing!: boolean;
  @Prop({ type: Boolean, default: true }) enableModeSwitch!: boolean;

  dialogOpen = false;
  unsupportedPluginDialog = false;
  isSwitchDissabled = false;
  mode = false;
  activeLink: GroupedNavigationItem = this.leftNav?.[0] || null;
  loading = true;
  currentTabIndex = 0;
  createDisabled = true;
  dynamicFormDataCopy: any;

  esm(templateStrings: TemplateStringsArray, ...substitutions: []): string {
    let js = templateStrings.raw[0];
    for (let i = 0; i < substitutions.length; i++) {
      js += substitutions[i] + templateStrings.raw[i + 1];
    }
    return 'data:text/javascript;base64,' + btoa(unescape(encodeURIComponent(js)));
  }

  changeMode(value: boolean) {
    this.dialogOpen = true;
    this.mode = value;
  }

  validateSupportedPlugins = async (): Promise<boolean> => {  
    const deckConfig = JSON.parse(JSON.stringify(DynamicRegFormModule.deckConfig));
    const usedPlugins = deckConfig.services[0]?.plugins;
    const usedPluginsNames = usedPlugins?.map((item: {name: string, enabled: boolean, config: unknown}) => item.name);
    let resp = 0;

    if (usedPluginsNames && usedPluginsNames.length > 0) {
      resp = (await API.checkPluginsSupport(usedPluginsNames)).data
    } 
    
    return !!resp
  }

  async confirmSwitch() {
    DynamicRegFormModule.setSimpleMode(!this.mode);
    await this.$nextTick();
    DynamicRegFormModule.setselectedFormSectionKey(!this.mode ? 'simple' : 'general');
    const oldDeck = JSON.parse(JSON.stringify(DynamicRegFormModule.deckConfig));
    this.populateFormWithDeck();
    this.checkValidation();
    await this.$nextTick();
    DynamicRegFormModule.resetDeck();
    DynamicRegFormModule.createDeckByFormDefinition();
    DynamicRegFormModule.updateRegFormData(oldDeck); 
  }

  cancelSwitch() {
    this.mode = !this.mode;
  }

  populateFormWithDeck(): void {
    DynamicRegFormModule.updateRegFormData();
  }

  async updateFormModuleScript(data: string): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const module = this.esm`${data}`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const convertData: DynamicConfig = await window.convertData(module);
    this.dynamicFormDataCopy = convertData;
    await DynamicRegFormModule.createNewDynamicForm(convertData);
  }

  routeUpdated(evt: GroupedNavigationItem): void {
    this.activeLink = evt;
    DynamicRegFormModule.updateSelectedFormSectionKey(evt.key);
    this.currentTabIndex = 0;
  }

  get unsupportedPluginDialogState(): boolean {
    return this.unsupportedPluginDialog
  }

  get switchStatus(): boolean {
    return this.isSwitchDissabled
  }

  get simpleMode(): boolean {
    return DynamicRegFormModule.simpleMode;
  }

  get isEdit(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewEdit;
  }

  get isPromoteProject(): boolean {
    return this.$route.name === RouteNames.ProjectPromoteNew;
  }

  get isVersion(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewVersion;
  }

  get leftNav(): GroupedNavigationItem[] {
    return DynamicRegFormModule.leftNavItems;
  }

  get sections(): DynamicFormField[] {
    if (this.simpleMode) {
      return filter(this.dynamicFormData, item => item.restrictTo === 'simple');
    }
    return filter(
      this.dynamicFormData,
      item => !item.restrictTo || item.restrictTo === this.activeLink.key,
    );
  }

  get dynamicFormData(): DynamicFormField[] {
    return DynamicRegFormModule.activeFormSectionItems || [];
  }

  get dataContext() {
    return DynamicRegFormModule?.deckConfig?.services?.[0] || {};
  }

  get env(): AppEnum {
    const penvId = this.$route.params.env;
    const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[penvId]?.environmentId ?? -1;
    return ensureAppEnum(EnumsModule.enumsById.environment[envId]);
  }

  async runCustomValidators(data: DynamicFormField[]) {
    const res = await runDynamicCustomValidators(
      flattenedItemsFor(data),
      DynamicRegFormModule.allFormSectionItems,
      this.env,
      DynamicRegFormModule.resourceData,
      this.dataContext,
      undefined,
      ProjectDetailsModule.proxyPathList,
      Platform.AZURE,
    );
    return res.hasErr;
  }

  async allFieldsValid() {
    if (await this.validateSupportedPlugins()) {
      this.createDisabled = true;
      return false;
    }

    let allData: FormSections[];

    if (this.simpleMode) {
      allData = DynamicRegFormModule.allFormSectionItems.filter(item => item.key === 'simple');
    } else {
      allData = DynamicRegFormModule.allFormSectionItems.filter(item => item.key !== 'simple');
    }

    const mapFn = allData.map(async item => {
      const isValid = await this.runCustomValidators(item.value || []);
      return { key: item.key, isValid };
    });
    return Promise.all(mapFn);
  }

  resetTabIndex(index: number): void {
    this.currentTabIndex = index;
  }

  onNestedFormMount(): void {
    if (this.isEdit || this.isVersion) {
      this.runValidation();
    }
  }

  changeUnsupportedPluginsDialogStatus(newStatus: boolean): void {
    this.unsupportedPluginDialog = newStatus
  }

  disableSwitch(): void {
    this.isSwitchDissabled = true;
  } 

  async runValidation(): Promise<boolean> {
    if (await this.validateSupportedPlugins()) {
      this.changeUnsupportedPluginsDialogStatus(true);
      this.disableSwitch();
      this.createDisabled = true;
      return false;
    }
    if (this.simpleMode) {
      const nestedForm = this.$refs.nestedForm as NestedForm;
      return await nestedForm.runSectionValidator();
    } else {
      const renderer = this.$refs.child as ApiRegistrationContainer;
      const nestedForm = renderer.$refs.nestedForm as NestedForm;
      return await nestedForm.runSectionValidator();
    }
  }

  created() {
    DynamicRegFormModule.setSimpleMode(true);
    this.mode = false;
    this.updateFormModuleScript(this.rendererData).then(() => {
      this.loading = false;
      DynamicRegFormModule.createDeckByFormDefinition();
      if(!(this.isEdit || this.isVersion)) {
        DynamicRegFormModule.updateDefaultAuthType();
        DynamicRegFormModule.updateDefaultValuesFromOpenApiSpec();
        DynamicRegFormModule.updateRegFormData(DynamicRegFormModule.deckConfig);
        if(DynamicRegFormModule.openApiSpecData !== undefined) {
          DynamicRegFormModule.setSimpleMode(false);
        }
      }
    });
  }

  @Watch('simpleMode')
  simpleModeChange() {
    this.resetTabIndex(0);
    if (this.simpleMode) {
      DynamicRegFormModule.updateSelectedFormSectionKey('simple');
    } else {
      if(DynamicRegFormModule.openApiSpecData === undefined) {
        this.activeLink = this.leftNav?.[0];
      } else {
        this.activeLink = this.leftNav?.[4];
      }
      DynamicRegFormModule.updateSelectedFormSectionKey(this.activeLink.key);
    }
    this.mode = !this.simpleMode;
  }

  @Watch('formDataChangeStatus')
  formDataChangeStatusChange(): void {
    this.$emit('validating', true);
    this.asyncValidate();
  }
  asyncValidate = debounce(this.checkValidation, 500);
  public async checkValidation() {
    try {
      const res: ValidationStatus[] =  await this.allFieldsValid();
      let disabled = false;
      res.forEach((response: any) => {
        if (!disabled) {
          disabled = response.isValid;
        }
      });
      this.createDisabled = disabled;
      this.$emit('valid', !disabled);
      this.$emit('validating', false);
      DynamicRegFormModule.updateDataChangeStatus(false);
      DynamicRegFormModule.updateValidationStatus(res);
    } catch(e) {
      //
    }
  }

  get formDataChangeStatus(): boolean {
    return DynamicRegFormModule.dataChangeStatus;
  }

  get firstTabItem(): DynamicFormField {
    return this.sections[0];
  }

  get lastTabItem(): DynamicFormField {
    return this.sections[this.sections.length - 1];
  }

  get currentTabItem(): DynamicFormField {
    return this.sections[this.currentTabIndex];
  }

  get nextTabItem(): DynamicFormField {
    return this.sections[this.currentTabIndex + 1];
  }

  get previousTabItem(): DynamicFormField {
    return this.sections[this.currentTabIndex - 1];
  }

  get isLastItem(): boolean {
    return get(this.currentTabItem, 'label') === get(this.lastTabItem, 'label');
  }

  get isFirstItem(): boolean {
    return get(this.currentTabItem, 'label') === get(this.firstTabItem, 'label');
  }

  nextButtonClick(): void {
    (this.$refs?.child as ApiRegistrationContainer)?.navigateToTabIndex(this.currentTabIndex + 1);
  }

  previousButtonClick(): void {
    (this.$refs?.child as ApiRegistrationContainer)?.navigateToTabIndex(this.currentTabIndex - 1);
  }

  get apiRegAnalytics(): ClickData {
    if (this.isEdit) {
      return {
        clickTarget: 'edit-api-registration',
      };
    }
    if (this.isVersion) {
      return {
        clickTarget: 'new-version-api-registration',
      };
    }
    return {
      clickTarget: 'new-api-registration',
    };
  }

  async createButtonClick(clickAnalytics: () => void): Promise<void> {
    const valid = await this.runValidation();
    if (!valid) {
      return;
    }
    clickAnalytics();
    this.$emit('submit');
  }
}

