
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import * as YAML from 'js-yaml';
import { editor as MonacoEditor, MarkerSeverity, Uri } from 'monaco-editor';
import { setDiagnosticsOptions, SchemasSettings } from 'monaco-yaml';

import FullScreenForm from '@/components/FullScreenForm.vue';
import MultiSplitPane from '@/components/split-pane/MultiSplitPane.vue';
import Pane from '@/components/split-pane/Pane.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { AUTH_SRC, RouteNames } from '@/constants';
import RegistrationFormRenderer from '@/components/RegistrationFormRenderer.vue';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { TextField } from '@gsk-tech/gsk-textfield/gsk-textfield';
import {
  getRegistrationService,
  saveFormConfiguration,
  saveRegistrationService,
  validateRegistrationService,
  getFormConfiguration,
  loadRegistrationFormRenderer,
  convertFormConfiguration,
  getDefaultFormConfiguration,
} from '@/api/admin-registration.api';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { DynamicRegFormModule } from '@/store/modules/dynamic-registration.module';
import GskSwitch from '@/components/gsk-components/GskSwitch.vue';
import type { DeckConfig, DeckItemConfig } from '@/components/form/dynamic-form.types';
import schema from './FormDefinitionSchema.json';
import { getCssClasses } from '@/utils/dynamic-registration';
import { TOLockEnvironment, TOLockTarget } from '@/types/two-otters.types';

type Editor = import('monaco-editor').editor.ICodeEditor;

const rightPaneDefaultFracs = [0.65, 0.35];
const formDefinitionSchemaUrl =
  'https://github.com/gsk-tech/developer-portal/tree/develop/client/src/views/admin/FormDefinitionSchema.json';

const defaultSchema: SchemasSettings = {
  uri: formDefinitionSchemaUrl,
  // @ts-expect-error TypeScript can’t narrow down the type of JSON imports
  schema,
  fileMatch: ['FormDefinitionSchema.json'],
};

setDiagnosticsOptions({
  enableSchemaRequest: true,
  hover: true,
  completion: true,
  validate: true,
  format: true,
  schemas: [defaultSchema],
});

@Component({
  components: {
    GskSwitch,
    RegistrationFormRenderer,
    GTextfield,
    GDialog,
    GButton,
    FullScreenForm,
    MultiSplitPane,
    Pane,
    IconMenu,
    'monaco-editor': () => import('vue-monaco'),
  },
})
export default class RegistrationFormTestingView extends Vue {
  formConfig = '';
  deckValid = false;
  loading = false;
  showDeckDialog = false;
  showCSSClassesDialog = false;
  deckPreviewCollapsed = false;
  formPreviewCollapsed = false;
  formConfigCollapsed = false;
  rightPaneFracs: number[] = [];
  leftPaneFracs: number[] = [];
  serviceName = '';
  validationViewCollapsed = true;
  consoleViewCollapsed = true;
  formConfigValid = true;
  formConfigErrors: string[] = [];
  consoleData = '';
  formPreviewLoading = true;
  formRendererData = '';
  cssClasses = getCssClasses();

  async created(): Promise<void> {
    DynamicRegFormModule.setOAuthMnemonicVal(AUTH_SRC.PING_OAUTH.mnemonic);
    window.addEventListener('resize', this.delayResizeEditor);
    await this.getFormConfiguration();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.delayResizeEditor);
  }

  get getIconMenuOptions() {
    return [
      {
        key: 'discard-changes',
        text: 'Discard Changes',
        handler: this.onDiscardChangesClicked,
      },
      {
        key: 'load-static',
        text: 'Load Static Definition',
        handler: this.onLoadStaticDefinitionClicked,
      },
    ];
  }

  async onLoadStaticDefinitionClicked() {
    await this.getDefaultFormConfiguration();
    this.onFormConfigChange(this.formConfig);
  }

  async onDiscardChangesClicked() {
    await this.getFormConfiguration();
    this.onFormConfigChange(this.formConfig);
  }

  async onFormPreviewReloadClicked() {
    await this.loadRegistrationFormRenderer();
    this.populateFormWithDeck();
  }

  async saveFormConfiguration(): Promise<void> {
    this.formConfigValidation();
    if (!this.formConfigValid) {
      this.validationViewCollapsed = false;
      return;
    }

    try {
      await saveFormConfiguration(this.formConfig);
      openSnackbar.call(this, 'Form Definition Successfully Updated');
    } catch (err) {
      openErrorSnackbar.call(
        this,
        (err as AxiosError)?.response?.data?.error || 'Error: Failed to update Form Definition',
      );
    }
  }

  async getFormConfiguration(): Promise<void> {
    try {
      const response = await getFormConfiguration();
      this.formConfig = response.data;
      this.formConfigEditor?.setValue(this.formConfig);
      await this.loadRegistrationFormRenderer();
    } catch (err) {
      openErrorSnackbar.call(
        this,
        (err as AxiosError)?.response?.data?.error || 'Error: Failed to get Form Definition',
      );
    }
  }

  async getDefaultFormConfiguration(): Promise<void> {
    try {
      const response = await getDefaultFormConfiguration();
      this.formConfig = response.data;
      this.formConfigEditor?.setValue(this.formConfig);
      await this.loadRegistrationFormRenderer();
    } catch (err) {
      openErrorSnackbar.call(
        this,
        (err as AxiosError)?.response?.data?.error || 'Error: Failed to get Form Definition',
      );
    }
  }

  async loadRegistrationFormRenderer(): Promise<void> {
    this.formPreviewLoading = true;
    const response = await loadRegistrationFormRenderer();
    this.formPreviewLoading = false;
    await this.updateRendererData(response.data);
  }

  back(): void {
    this.$router.safeBack(this.closeRoute);
  }

  async changeMode(value: boolean) {
    DynamicRegFormModule.setSimpleMode(value);
    DynamicRegFormModule.setselectedFormSectionKey(value ? 'simple' : 'service');
    await this.$nextTick();
    this.populateFormWithDeck();
  }

  get simpleMode(): boolean {
    return DynamicRegFormModule.simpleMode;
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.Admin,
    };
  }

  get deckMonacoOptions(): import('monaco-editor').editor.IStandaloneEditorConstructionOptions {
    return {
      tabSize: 2,
      minimap: {
        enabled: false,
      },
      // readOnly: true, // When the Form Preview is integrated, this should be activated
      automaticLayout: true,
    };
  }

  formConfigEditor: Editor | null = null;
  deckPreviewEditor: Editor | null = null;

  deckPreviewEditorDidMount(editor: Editor) {
    this.deckPreviewEditor = editor;
    editor.getModel()?.updateOptions({ tabSize: 2 });
  }

  onFormConfigChange = debounce(async e => {
    this.onFormConfigChangeDebounced(e);
  }, 1000);

  async onFormConfigChangeDebounced(e: string): Promise<void> {
    this.formConfig = e;
    try {
      this.formConfigValidation();
      if (this.formConfigValid) {
        const response = await convertFormConfiguration(e);
        await this.updateRendererData(response.data);
        DynamicRegFormModule.updateRegFormData();
      }
    } catch (e) {
      openErrorSnackbar.call(this, 'Failed to convert form configuration data. ' + e);
    }
  }

  async updateRendererData(data: string): Promise<void> {
    this.formRendererData = data;
    await this.$nextTick();
    const renderer = this.$refs['registrationFormRenderer'] as RegistrationFormRenderer;
    await renderer?.updateFormModuleScript(this.formRendererData);
  }

  populateFormWithDeck(): void {
    DynamicRegFormModule.updateRegFormData();
  }

  mounted(): void {
    const rightPane = this.$refs.rightPane as MultiSplitPane;
    rightPane.fracs = [...rightPaneDefaultFracs];
    rightPane.applyFracs();

    this.initFormConfigEditor();
  }

  initFormConfigEditor() {
    const options: import('monaco-editor').editor.IStandaloneEditorConstructionOptions = {
      minimap: {
        enabled: false,
      },
      automaticLayout: true,
      model: MonacoEditor.createModel(this.formConfig, 'yaml', Uri.parse(formDefinitionSchemaUrl)),
    };

    this.formConfigEditor = MonacoEditor.create(
      this.$refs['form-config-editor'] as HTMLElement,
      options,
    );

    this.formConfigEditor.onDidChangeModelContent(() => {
      const value = this.formConfigEditor?.getValue();
      if (this.formConfig !== value) {
        this.onFormConfigChange(value);
      }
    });
  }

  get deckConfig() {
    return DynamicRegFormModule.deckConfig;
  }

  get tags(): string[] {
    return DynamicRegFormModule.deckConfig.services?.[0].tags || [];
  }

  get deckYaml() {
    return YAML.dump(this.deckConfig);
  }

  onFormConfigErrorClick(index: number) {
    const markers = this.getModelMarkers();
    const marker = markers[index] || null;
    const editor = this.formConfigEditor;

    if (marker) {
      editor?.setPosition({ lineNumber: marker.startLineNumber, column: marker.startColumn });
      editor?.revealLineInCenter(marker.startLineNumber);
      editor?.focus();
    }
  }

  getModelMarkers() {
    return MonacoEditor.getModelMarkers({
      owner: 'yaml',
      resource: Uri.parse(formDefinitionSchemaUrl),
    });
  }

  formConfigValidation() {
    const markers = this.getModelMarkers();
    this.formConfigErrors = [];
    this.formConfigValid = !markers.length;

    for (const marker of markers) {
      if (marker.severity === MarkerSeverity.Hint) {
        continue;
      }
      this.formConfigErrors.push(marker.message);
    }
  }

  onValidateIconClick() {
    this.formConfigValidation();
    this.validationViewCollapsed = false;
  }

  toggleValidationView() {
    this.formConfigValidation();
    this.validationViewCollapsed = !this.validationViewCollapsed;
  }

  toggleConsoleView() {
    this.consoleViewCollapsed = !this.consoleViewCollapsed;
  }

  resizeEditor(): void {
    if (this.formConfigEditor) {
      this.formConfigEditor.layout();
    }
    if (this.deckPreviewEditor) {
      this.deckPreviewEditor.layout();
    }
  }

  fold() {
    this.formConfigEditor?.getAction('editor.foldAll').run();
  }
  unfold() {
    this.formConfigEditor?.getAction('editor.unfoldAll').run();
  }

  delayResizeEditor = debounce(() => this.resizeEditor(), 300);

  onPaneResize() {
    this.delayResizeEditor();
  }

  toggleDeckPreview(e: PointerEvent) {
    (e?.target as HTMLElement)?.blur();

    const rightPane = this.$refs.rightPane as MultiSplitPane;
    const rightPaneHeight = rightPane.$el.getBoundingClientRect().height;
    const offset = (rightPaneHeight - 58) / rightPaneHeight;

    if (this.deckPreviewCollapsed) {
      rightPane.fracs = this.rightPaneFracs;
    } else {
      if (this.formPreviewCollapsed) {
        rightPane.fracs = [...rightPaneDefaultFracs];
        this.formPreviewCollapsed = false;
        this.rightPaneFracs = rightPane.fracs;
        this.deckPreviewCollapsed = !this.deckPreviewCollapsed;
      } else {
        this.rightPaneFracs = rightPane.fracs;
        rightPane.fracs = [offset, 1 - offset];
      }
    }
    rightPane.applyFracs();
    this.deckPreviewCollapsed = !this.deckPreviewCollapsed;
  }

  toggleFormPreview(e: PointerEvent) {
    (e?.target as HTMLElement)?.blur();

    const rightPane = this.$refs.rightPane as MultiSplitPane;
    const rightPaneHeight = rightPane.$el.getBoundingClientRect().height;
    const offset = (rightPaneHeight - 48) / rightPaneHeight;

    if (this.formPreviewCollapsed) {
      rightPane.fracs = this.rightPaneFracs;
    } else {
      if (this.deckPreviewCollapsed) {
        this.deckPreviewCollapsed = !this.deckPreviewCollapsed;
        rightPane.fracs = [...rightPaneDefaultFracs];
        this.rightPaneFracs = rightPane.fracs;
        this.formPreviewCollapsed = !this.formPreviewCollapsed;
      } else {
        this.rightPaneFracs = rightPane.fracs;
        rightPane.fracs = [1 - offset, offset];
      }
    }
    rightPane.applyFracs();
    this.formPreviewCollapsed = !this.formPreviewCollapsed;
  }

  toggleFormConfig(e: PointerEvent) {
    (e?.target as HTMLElement)?.blur();
    this.formConfigCollapsed = !this.formConfigCollapsed;

    const pane = this.$refs.leftPane as MultiSplitPane;
    const paneWidth = pane.$el.getBoundingClientRect().width;
    const offset = (paneWidth - 46) / paneWidth;

    if (this.formConfigCollapsed) {
      this.leftPaneFracs = pane.fracs;
      pane.fracs = [1 - offset, offset];
    } else {
      pane.fracs = this.leftPaneFracs;
    }
    pane.applyFracs();
  }

  async onDeckValidationClick(): Promise<void> {
    this.deckValid = false;
    try {
      const res = await validateRegistrationService(
        this.serviceName,
        TOLockTarget.SERVICE,
        TOLockEnvironment.DEV,
        this.tags,
        this.deckConfig,
      );
      if (res.data) {
        const { error = 'Validation Failed!', valid, result } = res.data as any;
        if (valid) {
          this.deckValid = true;
          this.consoleData = result;
        } else {
          this.consoleData = error;
        }
        this.consoleViewCollapsed = false;
      }
    } catch (e) {
      this.consoleData = (e as AxiosError)?.response?.data?.error;
      this.consoleViewCollapsed = false;
    }
  }

  async onDeckSaveClick(): Promise<void> {
    try {
      const res = await saveRegistrationService(this.serviceName, this.deckYaml);
      if (res.data) {
        this.consoleData = res.data;
        this.consoleViewCollapsed = false;
      }
    } catch (e) {
      this.consoleData = (e as AxiosError)?.response?.data?.error;
      this.consoleViewCollapsed = false;
    }
  }

  async onDeckDialogSubmit(): Promise<void> {
    this.showDeckDialog = false;
    this.consoleViewCollapsed = true;
    this.consoleData = '';

    const el = (this.$refs['serviceName'] as GTextfield).$el as TextField;
    this.serviceName = el.value;

    try {
      const res = await getRegistrationService(
        this.serviceName,
        TOLockTarget.SERVICE,
        TOLockEnvironment.DEV,
        'json',
      );
      if (res?.data) {
        const deckConfig = res.data as DeckConfig;
        if (!('services' in deckConfig)) {
          deckConfig.services = [
            {
              name: '',
              routes: [{ name: 'Route1', paths: ['/default'] }],
            },
          ];
        }
        DynamicRegFormModule.setDeckConfig(deckConfig);
      }
    } catch (e) {
      this.consoleData = (e as AxiosError)?.response?.data?.error;
      this.consoleViewCollapsed = false;
    }
    await this.loadRegistrationFormRenderer();
    this.populateFormWithDeck();
  }
  onDeckDialogCancel() {
    this.showDeckDialog = false;
  }
}
