
import { Component, Prop, Vue } from 'vue-property-decorator';
import NestedForm from '@/components/form/NestedForm.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { DynamicFormField } from '@/components/form/dynamic-form.types';

@Component({
  components: {
    NestedForm,
    MaxWidth,
    GButton,
  },
})
export default class ApiRegistrationContainer extends Vue {
  @Prop({ type: Number, required: true }) currentTabIndex!: number;
  @Prop({ type: Array, required: true }) sections!: DynamicFormField[];

  public get tabItem() {
    return this.sections[this.currentTabIndex] || []
  }

  async navigateToTabIndex(val: number) {
    this.$emit('reset-tab-index', val)
  }
}
