
import {Component, Prop, Vue } from 'vue-property-decorator';
import RenderDynamicContainer from '@/components/form/RenderDynamicContainer.vue';
import * as types from './form.types';
import DynamicFormItem from './DynamicFormItem.vue';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {DynamicRegFormModule} from '@/store/modules/dynamic-registration.module';
import {DynamicFormField} from './dynamic-form.types';
import {fieldIndex, flattenedItemsFor, runDynamicCustomValidators} from '@/components/form/utils';
import { AppEnum, ensureAppEnum, Platform } from '@/types/enum.types';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { EnumsModule } from '@/store/modules/enums.module';
import { RouteNames } from '@/constants';

type VObs = InstanceType<typeof ValidationObserver>;

@Component({
  name: 'NestedForm',
  components: {
    NestedForm,
    DynamicFormItem,
    ValidationObserver,
    ValidationProvider,
    RenderDynamicContainer,
  },
})
export default class NestedForm extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) value!: DynamicFormField[];
  @Prop({ type: Boolean, required: true }) isItemContainer!: boolean;
  @Prop({ type: String, default: Platform.AZURE }) platformName!: string;
  $refs!: {
    topObserver: VObs;
  };

  get env(): AppEnum {
    const penvId = this.$route.params.env;
    const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[penvId]?.environmentId ?? -1;
    return ensureAppEnum(EnumsModule.enumsById.environment[envId]);
  }

  get isEdit(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewEdit;
  }

  get isVersion() {
    return this.$route.name === RouteNames.RegisterApiNewVersion;
  }

  get dataContext() {
    return DynamicRegFormModule?.deckConfig?.services?.[0] || {};
  }

  checkComponentStatus(field: DynamicFormField) {
    const fields = DynamicRegFormModule.showOnlyFieldsWithNames;
    return fields.length > 0 ? fields.indexOf(field.name) !== -1 : true;
  }

  mounted(): void {
    this.$emit('mounted');
  }

  displayField(field: DynamicFormField): boolean {
    try {
      if (field.display) {
        if (typeof field.display === 'boolean') {
          return field.display;
        } else if (typeof field.display === 'function') {
          let mode = 'create';
          if (this.isEdit) mode = 'edit';
          if (this.isVersion) mode = 'version';
          return field.display(fieldIndex(this.value), mode, this.env.name, this.platformName);
        }
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  disabledField(field: DynamicFormField): boolean {
    try {
      if (typeof field.disabled === 'boolean') {
        return field.disabled;
      } else if (typeof field.disabled === 'function') {
        let mode = 'create';
        if (this.isEdit) mode = 'edit';
        if (this.isVersion) mode = 'version';
        return field.disabled(fieldIndex(this.value), mode, this.env.name);
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  async updated() {
    setTimeout(async () => {
      await this.runCustomValidators(this.value);
    }, 0);
  }

  async allFieldsValid() {
    return await this.runCustomValidators(this.value);
  }

  async runCustomValidators(data: DynamicFormField[]) {
    const filterData = flattenedItemsFor(data)
    const res = await runDynamicCustomValidators(filterData, DynamicRegFormModule.allFormSectionItems, this.env, DynamicRegFormModule.resourceData, 
    this.dataContext, undefined, ProjectDetailsModule.proxyPathList, this.platformName);
    if (res.hasErr) {
      setTimeout(() => this.setServerErrors(res.serverErrors));
    }
    return res.hasErr;
  }

  setServerErrors(errors: types.ServerErrors): void {
    this.$refs.topObserver?.setErrors(errors);
  }

  customValidateField(errors: types.ServerErrors) {
    setTimeout(() => this.setServerErrors(errors));
  }

  async runSectionValidator(): Promise<boolean> {
    const formValid = await this.$refs?.topObserver?.validate();
    const hasCustomError = await this.allFieldsValid();
    return formValid && !hasCustomError;
  }
}
