export interface TOLockState {
  type: TOLockTarget;
  key: TOLockKey;
  isLocked: boolean;
  owner: string | null;
  hasOngoingRequest: boolean;
}

export interface TOLockStateResponse {
  key: TOLockKey;
  locked: boolean;
  ownerId?: string;
}

export enum TOLockEnvironment {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'PROD',
}

export enum TOLockTarget {
  SERVICE = 'service',
  CONSUMER = 'consumer',
}

export type TOLockKey =
  | 'Consumer-DEV'
  | 'Service-DEV'
  | 'Consumer-QA'
  | 'Service-QA'
  | 'Consumer-PROD'
  | 'Service-PROD';
